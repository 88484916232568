// @flow
import React, {useMemo, useContext, useCallback} from "react";
import AppHeaderNav from "../components/AppHeaderNav";
import {Route, Switch, withRouter} from "react-router-dom";
import type {RouterHistory} from "react-router-dom";
import type {ComponentRouteListItem} from "../components/AppHeaderNav";
import {AuthContext, filterRouteAllows, logout} from "../core/auth";
import {RouteList} from "../components/list-views/route-list";
import {CreateChildAccount} from "./parent/CreateChildAccount";
import {ParentRoutes} from "./parent/ParentRoutes";
import {CallFunction} from "./admin/CallFunction";
import {Button} from "arwes";
import {FindChild} from "./parent/FindChild";

const routes: Array<ComponentRouteListItem> = [
  {
    name: "List Route",
    url: "/",
    exact: true,
    comp: RouteList,
    allowRoles: ["child"],
    id: "child-list"
  },
  {
    name: "List Route",
    url: "/",
    exact: true,
    comp: ParentRoutes,
    allowRoles: ["parent"],
    id: "parent-list"
  },
  {
    name: "Create Child",
    url: "/newchild",
    comp: CreateChildAccount,
    allowRoles: ["parent"],
    id: "parent-child-add"
  },
  {
    name: "Find Child",
    url: "/find",
    exact: true,
    comp: FindChild,
    allowRoles: ["parent"],
    id: "parent-find-child"
  },
  {
    name: "Call Firebase Function",
    url: "/callFunction",
    exact: true,
    comp: CallFunction,
    allowRoles: ["sysAdmin"],
    id: "admin-function"
  }
];

function UserDash({history}: { history: RouterHistory }) {
  const {user} = useContext(AuthContext);
  const usrRoutes = useMemo(() =>
      filterRouteAllows((user && user.roles) || [], routes),
    [
      user,
      routes
    ]);

  const onLogout = useCallback(() => void logout().then(() => void history.push("/")));

  return <>
    <AppHeaderNav links={usrRoutes.map(({comp: _, ...route}) => route)}>
      <Button onClick={() => onLogout()} type="button">Log Out</Button>
    </AppHeaderNav>
    <Switch>
      {
        usrRoutes.map(route =>
          <Route key={`${route.url}-${route.name}-${route.id}`} exact={route.exact || false} path={route.url}
                 component={route.comp}/>
        )
      }
    </Switch>
  </>
}

export default withRouter(UserDash);
