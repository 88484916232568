// @flow
import React from "react";
import type {ComponentRouteListItem} from "../components/AppHeaderNav";
import AppHeaderNav from "../components/AppHeaderNav";
import {Link, Route, Switch} from "react-router-dom";
import Home from "./public-pages/Home";
import {Button} from "arwes";

const routes: Array<ComponentRouteListItem> = [
  {
    name: "Home",
    url: "/",
    exact: true,
    comp: Home
  }
];

export default function PublicView() {
  return <>
    <AppHeaderNav links={routes.map(({comp: _, ...route}) => route)} disableLogo={true}>
      <div style={{flexWrap: "nowrap"}}>
        <Link to="/login">
          <Button animate>Login</Button>
        </Link>
        <Link to="/login?register" className="m-l-15">
          <Button animate>Register</Button>
        </Link>
      </div>
    </AppHeaderNav>
    <Switch>
      {
        routes.map(route => <Route key={`${route.url}-${route.name}`} exact={route.exact || false} path={route.url}
                                   component={route.comp}/>)
      }
    </Switch>
  </>
}
