import React, {useState, useContext} from "react";
import RoutePicker from "../components/map/route-picker";
import {firebaseDb} from "../core/firebase";
import {AuthContext} from "../core/auth";
import {Button} from "arwes";

export default function PickRoute() {
  const {user} = useContext(AuthContext);
  const [path, setPath] = useState(null);
  const [routeName, setRouteName] = useState("");

  const saveData = () => {
    firebaseDb.ref().child("routes").child(user.routesId).push({
      pinpoints: path,
      name: routeName,
      user: user._id
    })
  };
  return <>
    <RoutePicker setEncodeString={str => setPath(str)}/>
    <input type="text" onChange={({target: {value}}) => void setRouteName(value)} placeholder="Name of Route" required/>
    <Button type="button" onClick={() => saveData()}>
      Save Path
    </Button>
  </>
}
