// @flow
import {withFormik} from "formik";
import * as Yup from "yup";

type yupHandleSubmitType<T> = ({ payload: T, obj: { setErrors: (fields: { [field: string]: string }) => void, setSubmitting: (boolean) => void } }) => void;
export type AuthValidatorSubmitType = yupHandleSubmitType<{ email: string, password: string, passwordconfirm: string }>;

export const authValidator = (handleSubmit: AuthValidatorSubmitType) => withFormik({
  validationSchema: Yup.object().shape({
    email: Yup.string()
      .email("Must be a valid email address")
      .required("Email is required."),
    password: Yup.string()
      .when("register", (reg, schema) => {
        if (!!reg) {
          return schema.matches(/.{8,}/, {
            excludeEmptyString: true,
            message: "Password must have at least eight characters"
          })
            .matches(/[a-z]/, {
              excludeEmptyString: true,
              message: "Password must have a lowercase character"
            })
            .matches(/[A-Z]/, {
              excludeEmptyString: true,
              message: "Password must have an uppercase character"
            })
            .matches(/\d/, {
              excludeEmptyString: true,
              message: "Password must have a numerical character"
            })
            .matches(/[@$!%*?&]/, {
              excludeEmptyString: true,
              message: "Password must have a special character"
            });
        }
        return schema;
      })
      .required("Password is required."),
    passwordconfirm: Yup.string()
      .when("register", (reg, schema) => {
        if (!!reg) {
          return schema.oneOf([
            Yup.ref("password"),
            null
          ], "Passwords must match")
            .required("Password confirm is required")
        }
        return schema;
      }),
    register: Yup.boolean()
  }),
  mapPropsToValues: ({user, register}) => ({
    ...user,
    register
  }),
  handleSubmit
});
