// @flow
import React, {useState, useEffect, useContext} from 'react';
import {List} from "arwes";
import {firebaseDb} from "../../core/firebase";
import type {Route} from "../../types/route";
import RouteDisplay from "../map/route-display";
import {AuthContext} from "../../core/auth";

export function RouteList() {
  const {user} = useContext(AuthContext);
  const [routes, setRoutes] = useState<Array<Route & {_id: string}>>([]);

  const [selRoute, setSelRoute] = useState<Route>(null);

  useEffect(() => {
    const routesRef = firebaseDb.ref<Route>(`routes/${user.routesId}`);
    function getRoutesVals(insertRoutes) {
      const routeVals = insertRoutes.val();
      const itemsToSet = Object.keys(routeVals).map(routeKey => {
        const routeItem = routeVals[routeKey];
        routeItem._id = routeKey;
        return routeItem;
      });

      setRoutes(itemsToSet);
    }
    routesRef.on('value', getRoutesVals);
    return () => void routesRef.off('value', getRoutesVals);
  }, []);

  return (<>
    {selRoute && <RouteDisplay data={[{locations: selRoute.pinpoints, color: '#9d0017'}]}/>}
    {routes && routes.length > 0 ? <List node='ul'>
    {routes.map(route => <li onClick={() => setSelRoute(route)} className="pointer" key={route._id}>{route.name}</li>)}
    </List> : <p style={{padding: '30px'}}>No routes created. {user && user.roles && user.roles.includes('child') ?
      'Have your parent(s) create one for you to see!' : 'You can create one in the "List Routes" tab'}</p>}
  </>)
}
