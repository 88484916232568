import React, {useMemo, useContext, useState} from "react";
import {authValidator} from "../../components/auth/validator";
import {addChildToUser, AuthContext, createUser} from "../../core/auth";
import type {AuthValidatorSubmitType} from "../../components/auth/validator";
import {AuthForm as AuthBaseForm} from "../../components/auth/auth-form";
import {firebaseSecondaryAuth} from "../../core/firebase";

export function CreateChildAccount() {
  const [newChild, setNewChild] = useState(null);
  const {user, changeUser} = useContext(AuthContext);
  const AuthForm = useMemo(
    () => authValidator(
      (async (payload, {setSubmitting, setErrors, resetForm}) => {
        try {
          const childUsr = await createUser(payload.email, payload.password, ["child"], firebaseSecondaryAuth, user);
          await addChildToUser(user, childUsr._id);
          setSubmitting(false);
          const newUsrObj = Object.assign({}, user);
          newUsrObj.children = (newUsrObj.children || {});
          newUsrObj.children[childUsr._id] = true;
          changeUser(newUsrObj);
          setNewChild(childUsr);
          resetForm();
        } catch ({code, message}) {
          console.error(code, message);
          setSubmitting(false);
          setErrors({message});
        }
      }: AuthValidatorSubmitType)
    )(AuthBaseForm),
    []
  );

  return <>
    <AuthForm register={true}/>
    {
      newChild &&
      <p>New account created with email of: {newChild.email}</p>
    }
  </>;
}
