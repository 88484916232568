import {createContext} from "react";
import {EMPTY, fromEvent} from "rxjs";
import type {Observable} from "rxjs";
import {ObjectMap} from "../helpers/object-helpers";

const $initMap = fromEvent(window, 'initMap');

const exportObj = {
  $initMap
};

export const RxJSContext = createContext((ObjectMap(exportObj, () => EMPTY): {[key: $Keys<typeof exportObj>]: Observable}));

export default exportObj;
