// @flow
import type {ComponentType} from "react";
import React from "react";
import type {Location} from "react-router-dom";
import {Link, withRouter} from "react-router-dom";
import type {AuthRoles} from "../types/auth";
import {Button, Line} from "arwes";

export type RouteType = {
  name: string,
  url: string,
  location: Location
};

export type ComponentRouteListItem = RouteType & {
  comp: ComponentType,
  allowRoles?: Array<AuthRoles>,
  exact?: boolean
}

function AppHeaderNav({links, children, location, disableLogo = false}: { links: RouteType[], children: ComponentType[], disableLogo?: boolean }) {
  return <div>
    <nav style={{
      height: "50px",
      display: "flex",
      justifyContent: "space-between",
      flexWrap: "nowrap",
      padding: '20px',
      boxSizing: 'content-box'
    }}>
      {!disableLogo && <img style={{"maxHeight": "100%"}} src="/logo.png" alt="Jeterra Logo"/>}
      <div>
        {
          (!links || links.length > 1) && links.map(({url, name}) =>
            <Link to={url} key={`${url}-${name}`}
                  className={location.pathname === url ? "nav-match m-l-15" : "m-l-15"}>
              <Button animate>{name}</Button>
            </Link>
          )
        }
      </div>
      {
        children || <div/>
      }
    </nav>
    <Line animate/>
  </div>
}

export default withRouter(AppHeaderNav);
