// @flow
import {withFormik} from "formik";
import * as Yup from "yup";

type yupHandleSubmitType<T> = ({ payload: T, obj: { setErrors: (fields: { [field: string]: string }) => void, setSubmitting: (boolean) => void } }) => void;
export type AuthValidatorSubmitType = yupHandleSubmitType<{ email: string, name: string, message: string }>;

export const contactValidator = (handleSubmit: AuthValidatorSubmitType) => withFormik({
  validationSchema: Yup.object().shape({
    email: Yup.string()
      .email("Must be a valid email address")
      .required("Email is required."),
    name: Yup.string()
      .required("Name is required."),
    message: Yup.string()
      .required("A message is required."),
  }),
  mapPropsToValues: ({user, register}) => ({
    ...user,
    register
  }),
  handleSubmit,
  displayName: "MyForm",
});
