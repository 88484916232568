import React, {useState} from "react";
import GoogleMap from "./google-map";

export default function RoutePicker({setEncodeString}: {setEncodeString: (path: string) => void}) {
  const google = window.google;
  const [onMapClickListener, setMapClickListener] = useState(false);

  const onMapEnable = (localMap) => {
    if (localMap) {
      const polyLine = new google.maps.Polyline({
        strokeColor: '#9d0017',
        strokeOpacity: 1,
        strokeWeight: 3,
        map: localMap
      });

      setMapClickListener(() =>  google.maps.event.addListener(localMap, "click", (event) => {
        const path = polyLine.getPath();
        // Because path is an MVCArray, we can simply append a new coordinate
        // and it will automatically appear
        path.push(event.latLng);
        const encodeString = google.maps.geometry.encoding.encodePath(path);
        if (encodeString && setEncodeString) {
          setEncodeString(encodeString)
        }
      }));
    }
  };

  const onMapDisable = (localMap) => {
    if (localMap) {
      google.maps.event.removeListener(onMapClickListener);
    }
  };

  return <GoogleMap onMapEnable={onMapEnable}
                    onMapDisable={onMapDisable}/>
}
