import React, {useState, useEffect, useMemo} from "react";
import GoogleMap from "./google-map";
import {getMost} from "../../helpers/location-helpers";


type PolyLineData = {
  color: string,
  locations: LocationData
}

type PolyLineDataProp = Array<PolyLineData & {name: string}> | [PolyLineData & {name?: string}]

type LocationData = Array<{lat: number, long: number}> | string;

export default function RouteDisplay({data}: {data: PolyLineDataProp}) {
  const google = window.google;
  const [localMap, setLocalMap] = useState(null);

  useEffect(() => {
    if (localMap && data) {
      const polyLines = data.map(polyData => {
        const polyLine = new google.maps.Polyline({
          strokeColor: polyData.color,
          strokeOpacity: 1,
          strokeWeight: 3,
          map: localMap
        });
        const path = polyLine.getPath();
        let locations;
        if (typeof polyData.locations === 'string') {
          locations = google.maps.geometry.encoding.decodePath(polyData.locations);
        } else {
          locations = polyData.locations.map(({lat, long}) => ({lat, lng: long}));
        }

        locations.forEach(latLng => {
          path.push(latLng);
        });

        const mostSW = getMost('SW', locations);
        const mostNE = getMost('NE', locations);
        const mapBounds = new google.maps.LatLngBounds(mostSW, mostNE);
        localMap.panToBounds(mapBounds, 2);
        localMap.setCenter(mapBounds.getCenter());

        return polyLine;
      });

      return () => polyLines.forEach(polyLine => polyLine.setMap(null));
    }
  }, [data, localMap]);

  const onMapEnable = (scopeMap) => {
    if (scopeMap) {
      setLocalMap(scopeMap);
    }
  };

  return <GoogleMap onMapEnable={onMapEnable}/>
}
