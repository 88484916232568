import React, {useState} from "react";
import classnames from "classnames";
import "./style.css";
import {Frame} from "arwes";

export const TextInput = ({type, id, label, error: errorStr, value, onChange, className, ...props}) => {
  const inputErr = classnames(
    {
      "error": !!errorStr,
    },
    "text-input"
  );

  return (
    <div className={className}>
      <label htmlFor={id}>
        {label}
        {errorStr && <div className="msg-wrapper error">
          <div>{errorStr}</div>
        </div>}
      </label>
      <input
        id={id}
        className={inputErr}
        type={type}
        value={value}
        onChange={onChange}
        {...props}
      />
    </div>
  );
};

export const TextArea = ({error: errorBool, prefilledStr = "", onBlur: onBlurProp, className, onFocus: onFocusProp, id, ...props}) => {
  const [focused, setFocused] = useState(false);

  const frameClasses = classnames(
    {
      error: !!errorBool,
      focused: !!focused
    },
    "textarea-frame",
    className
  );

  return (
    <>
      <Frame
        animate
        level={3}
        corners={4}
        className={frameClasses}
      >
      <textarea className="full-width"
                id={id}
                onFocus={event => {
                  setFocused(true);
                  if (onFocusProp) {
                    onFocusProp(event);
                  }
                }}
                onBlur={event => {
                  setFocused(false);
                  if (onBlurProp) {
                    onBlurProp(event);
                  }
                }}
                value={prefilledStr}
                {...props}
      />
      </Frame>
      {errorBool && <p style={{margin: '10px 0 0'}} className="error"> {errorBool} </p>}
    </>
  )
};
