import React from "react";
import {RouteList} from "../../components/list-views/route-list";
import PickRoute from "../PickRoute";
import {Col, Row} from "arwes";

export function ParentRoutes() {

  return <Row>
    <Col s={12} m={6}>
      <PickRoute/>
    </Col>
    <Col s={12} m={6}>
      <RouteList/>
    </Col>
  </Row>;
}
