// @flow

import {createContext} from "react";
import type {AuthRoles, User} from "../types/auth";
import {firebaseAuth, firebaseDb} from "./firebase";
import {BaseUser, ParentUser} from "../types/auth";
import type {ComponentRouteListItem} from "../components/AppHeaderNav";

export const AuthContext = createContext(({
  user: null,
  changeUser: () => {
  }
}: { user: User, changeUser: ((user: User) => void) }));

export const userState = {
  user: null,
  changeUser
};

function changeUser(user) {
  this.setState({
    user
  });
}

export async function createUser(email: string,
                                 password: string,
                                 roles: AuthRoles[],
                                 firebaseAuthRoot = firebaseAuth,
                                 contextUser = null): BaseUser & { _id: string } {
  const {user} = await firebaseAuthRoot.createUserWithEmailAndPassword(email, password);
  const newUsr = new BaseUser({roles});
  if (user.displayName) {
    newUsr.firstName = user.displayName.split(/\s/)[0];
  }
  if (user.email) {
    newUsr.email = user.email;
  }
  if (roles.includes("child") && contextUser) {
    newUsr.routesId = contextUser.routesId;
  } else {
    newUsr.routesId = firebaseDb.ref().child(`routes`).push().key;
  }
  await firebaseDb.ref().child(`users/${user.uid}`).set(JSON.parse(JSON.stringify(newUsr)));
  newUsr._id = user.uid;
  return newUsr;
}

export async function addChildToUser(user: User, childUid: string) {
  await firebaseDb.ref().child(`users/${user._id}/children`).update({[childUid]: true});
  await firebaseDb.ref().child(`users/${childUid}/parents`).update({[user._id]: true});
}

export async function loginUser(email: string, password: string) {
  const {user} = await firebaseAuth.signInWithEmailAndPassword(email, password);
  return getUsrData(user);
}

export function logout() {
  return firebaseAuth.signOut();
}

export async function getUsrData(user): BaseUser & { _id: string } {
  const userInstance = await firebaseDb.ref().child(`users/${user.uid}`).once("value");
  let usrData = userInstance.val();
  if (!usrData) {
    usrData = new ParentUser({
      roles: ["parent"]
    });
    if (user.displayName) {
      usrData.firstName = user.displayName.split(/\s/)[0];
    }
    if (user.email) {
      usrData.email = user.email;
    }
    await firebaseDb.ref().child(`users/${user.uid}`).set(JSON.parse(JSON.stringify(usrData)));
  }
  usrData._id = user.uid;
  return usrData;
}

export function filterRouteAllows(usrRoles: Array<AuthRoles>, routes: ComponentRouteListItem[]) {
  if (!usrRoles) {
    return [];
  }
  if (usrRoles.includes("admin")) {
    return routes;
  }
  return routes.filter(
    route =>
      !route.allowRoles ||
      route.allowRoles.some(allowRole => usrRoles.includes(allowRole))
  )
}
