import React from "react";
import {TextArea, TextInput} from "../form";
import {Button, Header} from "arwes";

export const ContactUsBaseForm = (props) => {
  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting
  } = props;
  return (
    <form className="full-width"
          onSubmit={handleSubmit}>
      <Header animate>
        <h1 style={{ margin: 0 }}>Contact Us</h1>
      </Header>
      <TextInput
        className="m-t-15"
        id="name"
        type="text"
        label="Full Name"
        placeholder="Name Here"
        error={touched.name && errors.name}
        value={values.name}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <TextInput
        className="m-t-15"
        id="email"
        type="text"
        label="Email"
        placeholder="Email Here"
        error={touched.email && errors.email}
        value={values.email}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <Header animate className="m-t-15">
        <h4 style={{ margin: 0 }}>Content</h4>
      </Header>
      <TextArea
        className="m-t-15"
        id="message"
        type="text"
        placeholder="Message for us to see goes here"
        error={touched.message && errors.message}
        value={values.message}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <Button className="full-width" animate type="submit"
              style={{marginTop: '20px'}}
              disabled={isSubmitting}>
        Submit
      </Button>
    </form>
  );
};
