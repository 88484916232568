// @flow
import type {Achievement} from "./achievement";
import type {Unlockable} from "./unlockable";
import type {Address} from "./address";
import type {School} from "./school";
import {mapConstructor} from "../helpers/object-helpers";

export type AuthRoles = "parent" | "child" | "sysAdmin";

export type UserLocation = {
  location: {
    lat: number,
    long: number
  },
  // This should be removed
  user: string,
  // Should actually be datetimes
  created: Date
}

type TrackedTime = {
  // Should actually be datetimes
  startTime: Date,
  endTime: Date,
  locations: UserLocation[]
}

export class BaseUser {
  constructor(props) {
    mapConstructor.call(this, props);
  }

  // This should not be saved to database, but should be ID of user
  _id: string = '';

  // Can use the same role, but provide different experiences based on. Don't need city name, use geofence admin
  roles: $ReadOnlyArray<AuthRoles> = [];
  // For data targeting mostly
  birthdate: Date = undefined;
  firstName: string = '';
  lastName: string = '';
  //  user's home address
  home: Address = undefined;
  locations: UserLocation[] = [];
  //  A list of gathered achievements by the user
  achievements: Achievement[] = [];
  //  A list of gathered achievements by the user
  unlockables: Unlockable[]  = [];
  //  A list of gathered achievements by the user
  xp: number = 0;
  // an array of when the user checked into the app/website. this will need to be manually tracked as user being logged in would be different
  checkins: Date[] = [];
  //  A boolean to check if the user is active. Will help parents see if location data is accurate/current
  isActive: boolean = false;
  //  A date to see when `isActive` was last updated. If it was last updated past 7 hours (we can update this Boolean once per hour using an Android event timer pretty easily), treat `isActive` as if it's false
  isActiveEdited: Date = new Date();
  /**
   * Our authentication model is relatively complicated out of necessity. Because we're using both Unity and native code
   * (using native code as Unity does not allow background code to be ran otherwise, which provides us the ability to
   * track child accounts upon the parent request), we need to share authentication code between native code and Unity Firebase.
   * otherwise, the user would need to login twice, providing a sub-par experience for the end-user. As a result, we
   * allow the user to login using a username and password for the first time, allowing initial auth. After this, we request
   * a JWT from Firebase ALA Firebase Function, pass that JWT to an Android service to encrypt and store that JWT in a Keystore,
   * for us to access whenever we reopen the Jeterra app. In doing so, we can keep a user logged in on a device for extended
   * periods of time and share auth instances between Android (which can now listen for calls for a user's device now that
   * it's logged into Firebase) and Unity (for the rest of the game/app)
   */
  // Devices that the user is authenticated into currently
  devices: Array<{
    // The name of the device
    name: string,
    // String of device model, so that we can display proper device information
    deviceModel: string,
    /**
     * This is the identifiable token generated by Firebase upon device authentication instantiation
     * This token is then used later to be able to query the device for location services upon parental request
     * @see {@link https://firebase.google.com/docs/cloud-messaging/android/client#sample-register|Firebase Android Code Setup}
     */
    messagingTokenIdentity: string
  }> = [];
  // This is the UUID that links the user with all routes
  routesId: string = '';
}

export class ChildUser extends BaseUser {
  constructor(props) {
    super();
    mapConstructor.call(this, props);
  }

  //  If student, the ID of school they go to
  school: School = undefined;
  /**
   * If child, a unique dictionary of parents they're associated with
   * This is not used for auth verification, only for purposes such as "send notification to parent" where the direct
   * link is needed
   */
  parents: {[key: string]: boolean} = {};

  trackedTimes: {[key: string]: TrackedTime}
}

export class ParentUser extends BaseUser {
  constructor(props) {
    super();
    mapConstructor.call(this, props);
  }

  // If parent, a unique dictionary of children they're associated with
  children: {[key: string]: boolean} = {};

  get childrenArr(): string[] {
    return Object.keys(this.children || {});
  }

  set childrenArr(val: string[]) {
    this.children = val.reduce((prev, key) => {
      prev[key] = true;
      return prev;
    }, {})
  }
}

export type User = ParentUser | ChildUser;
