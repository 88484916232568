// @flow

import React, {useContext, useEffect, useState} from "react";
import {AuthContext} from "../../core/auth";
import {firebaseDb} from "../../core/firebase";
import {ParentUser} from "../../types/auth";
import {Button, Col, Header, Row} from "arwes";
import "./FindChild.css";
import {sendDeviceLocationRequest} from "../../services/request-user-data";
import type {UserLocation} from "../../types/auth";
import SeeLocation from "../../components/map/see-location";

export function FindChild() {
  const {user} = useContext(AuthContext);

  const [childrenData, setChildrenData] = useState([]);
  const [selectedChild, setSelectedChild] = useState(null);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [lastLocation, setLastLocation] = useState<UserLocation>(null);

  useEffect(() => {
    if (user) {
      if (!(user: ParentUser).childrenArr || (user: ParentUser).childrenArr.length === 0) {
        return;
      }
      const childrenData = (user: ParentUser).childrenArr.map(async childId => {
        const userInstance = await firebaseDb.ref().child(`users/${childId}`).once("value");
        return userInstance.val();
      });
      Promise.all(childrenData)
        .then(data => {
          setChildrenData(data)
        })
        .catch(err => console.error(err));
    }
  }, [user]);

  useEffect(() => {
    setSelectedDevice(null)
  }, [selectedChild]);

  useEffect(() => {
    if (user && selectedChild) {
      const childLocationsRef = firebaseDb.ref("users/" + (user: ParentUser).childrenArr[selectedChild.index] + "/locations").limitToLast(1);
      childLocationsRef.on("value", snapshot => {
        const snapshotVal = snapshot.val();
        if (!snapshotVal) {
          return;
        }
        const keys = Object.keys(snapshotVal);
        if (keys.length === 0) {
          return;
        }
        setLastLocation(snapshotVal[keys[0]]);
      });
      return () => childLocationsRef.off();
    } else {
      setLastLocation(null);
    }
  }, [
    user,
    selectedChild
  ]);

  return !(childrenData && childrenData.length) ?
    <>
      <Header animate>
        <h1 style={{margin: 0}}>No Children Found</h1>
      </Header>
      <p>To add a child, click the "Create Child" button above</p>
    </> :
    <Row>
      <Col s={12} m={2}>
        <div style={{marginBottom: '15px'}}>
          {childrenData.map((child, i) => {
            const initials = `${(child.firstName && child.firstName[0].toUpperCase()) || ""}${(child.lastName && child.lastName[0].toUpperCase()) || ""}`;
            return <button
              key={i}
              onClick={() => setSelectedChild({
                child,
                index: i
              })}
              aria-label={`Select to see ${child.firstName || ""}${(child.lastName && ` ${child.lastName}`) || ""}'s devices`}
              className={`${selectedChild && i === selectedChild.index ? "active " : ""}childBtn`}>
              {initials}
            </button>
          })}
        </div>
        <div style={{marginBottom: '15px'}}>
          {
            selectedChild && selectedChild.child && selectedChild.child.devices && (
              selectedChild.child.devices.length ?

            selectedChild.child.devices.map(device =>
              <Button key={device.messagingTokenIdentity} onClick={() => setSelectedDevice(device)}
                      active={selectedDevice && selectedDevice.messagingTokenIdentity === device.messagingTokenIdentity}>
              {device.name}
              </Button>
            ) : <p>No Devices found for this child. They need to login to an Android device with the Jeterra app for you to query their location</p>
            )
          }
        </div>

        {
          selectedDevice &&
          <Button onClick={() => {
            sendDeviceLocationRequest((user: ParentUser).childrenArr[selectedChild.index], selectedDevice.messagingTokenIdentity)
              .then(a => console.log(a))
              .catch(e => console.error(e));
          }}>Get Location of Device</Button>
        }

      </Col>
      <Col s={12} m={10}>
        {
          lastLocation && lastLocation.location &&
          <SeeLocation lat={lastLocation.location.lat} lng={lastLocation.location.long}/>
        }
      </Col>
    </Row>;
}
