// @flow
import React, {useContext, useMemo, useState} from "react";
import {AuthContext, createUser, loginUser} from "../core/auth";
import type {Location} from "react-router-dom";
import {Redirect, withRouter} from "react-router-dom";
import {parse as queryParse} from "query-string";
import {AuthForm as AuthBaseForm} from "../components/auth/auth-form";
import {authValidator} from "../components/auth/validator";
import type {AuthValidatorSubmitType} from "../components/auth/validator";
import {Project, Appear, Frame} from "arwes";

function Login({location}: { location: Location }) {
  const {user, changeUser} = useContext(AuthContext);

  const isRegisterPage = useMemo(() => {
    const queryProps = queryParse(location.search, {ignoreQueryPrefix: true});
    return !!(queryProps && queryProps["register"] !== undefined);
  }, [location]);

  const AuthForm = useMemo(
    () => authValidator(
      ((payload, {setSubmitting, setErrors}) => {
        const submitFunction = isRegisterPage !== false ?
          createUser(payload.email, payload.password, ["parent"]) :
          loginUser(payload.email, payload.password);

        submitFunction
          .then(user => {
            setSubmitting(false);
            console.log('Logged in');
            changeUser(user);
          })
          .catch(({code, message}) => {
            console.error(code, message);
            setSubmitting(false);
            setErrors({message});
          });
      }: AuthValidatorSubmitType)
    )(AuthBaseForm),
    [isRegisterPage]
  );
  return user ?
    <Redirect to="/"/> :
    (AuthForm &&
        <div style={{ padding: 20, height: 'calc(100vh - 20px)' }}>
          <Project
            animate
            className="full-height"
            header={isRegisterPage ? "REGISTER" : "SIGN IN"}
            Frame={props => <Frame {...props} className="full-height"/>}
          >
            {anim => (
              <Appear className="full-width full-height" show={anim.entered}>
                <AuthForm className="full-width" user={{
                  password: "",
                  passwordconfirm: "",
                  email: ""
                }} register={isRegisterPage}/>
              </Appear>
            )}
          </Project>
        </div>
    ) || null
}

export default withRouter(Login);
