import React from 'react';
import {TextInput} from "../form";
import {Button} from "arwes";
import {Form} from "formik";

const AuthBaseForm = props => {
  const {
    values,
    touched,
    errors,
    dirty,
    handleChange,
    handleBlur,
    handleReset,
    isSubmitting
  } = props;

  return (
    <Form className="full-width">
      <TextInput
        id="email"
        type="text"
        label="Email"
        placeholder="Email Here"
        error={touched.email && errors.email}
        value={values.email || ''}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <TextInput
        id="password"
        type="password"
        label="Password"
        placeholder="Password Here"
        error={touched.password && errors.password}
        value={values.password || ''}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      {
        values.register &&
        <TextInput
        id="passwordconfirm"
        type="password"
        label="Confirm Password"
        placeholder="Confirm Password"
        error={touched.passwordconfirm && errors.passwordconfirm}
        value={values.passwordconfirm || ''}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      }

      {errors.message && <p className="error">{errors.message}</p>}

      <Button animate type="submit"
              style={{marginTop: '20px'}}
              disabled={isSubmitting}>
        Submit
      </Button>
      <Button
        animate
        type="button"
        className="m-l-15 outline"
        onClick={handleReset}
        style={{marginTop: '20px'}}
        disabled={!dirty || isSubmitting}
      >
        Reset
      </Button>
    </Form>
  );
};

export const AuthForm = AuthBaseForm;
