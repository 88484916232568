import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyAI_jxY6P9-ffkjl7fYcFNU8S4AKVFW4tI",
  authDomain: "jeterra-aabb11.firebaseapp.com",
  databaseURL: "https://jeterra-aabb11.firebaseio.com",
  projectId: "jeterra-aabb11",
  storageBucket: "jeterra-aabb11.appspot.com",
  messagingSenderId: "97432100056"
};

export const firebaseApp = firebase.initializeApp(firebaseConfig);
// Used for creating child accounts, primarily
export const firebaseSecondaryApp = firebase.initializeApp(firebaseConfig, 'Secondary');
export const firebaseAuth = firebaseApp.auth();
export const firebaseSecondaryAuth = firebaseSecondaryApp.auth();
export const firebaseDb = firebaseApp.database();
export const firebaseFunc = firebaseApp.functions();
