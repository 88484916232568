import React, {useMemo, useState} from "react";
import {ContactUsBaseForm} from "./contact-base-form";
import {contactValidator} from "./validator";
import {firebaseFunc} from "../../core/firebase";

export const ContactUsForm = (props) => {
  const [submitted, setSubmitted] = useState(false);
  const ContactUsForm = useMemo(() => contactValidator(async (payload, {setSubmitting, setErrors, resetForm}) => {
    try {
      const firebaseNamedFunc = firebaseFunc.httpsCallable('sendContactEmail');
      await firebaseNamedFunc({data: {
          name: payload.name,
          emailAddress: payload.email,
          message: payload.message
        }});
      setSubmitting(false);
      resetForm();
      setSubmitted(true);
    } catch ({code, message}) {
      console.error(code, message);
      setSubmitting(false);
      setErrors({message});
    }
  })(ContactUsBaseForm), [submitted]);

  return <>
    <ContactUsForm {...props}/>
    {submitted && <p>Thank you for contacting us, we'll be in touch soon!</p>}
  </>;
};
