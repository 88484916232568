import React, {useEffect, useMemo, useState, useCallback} from "react";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";

import {AuthContext, getUsrData} from "./core/auth";
import rxjsVal, {RxJSContext} from "./core/rxjs";
import PrivateRoute from "./components/ProtectedRoute";
import PublicView from "./routes/PublicView";
import Login from "./routes/Login";
import UserDash from "./routes/UserDash";
import ErrorBoundary from "./components/ErrorBoundary";
import {firebaseAuth} from "./core/firebase";
import {Arwes, createSounds, createTheme, SoundsProvider, ThemeProvider} from "arwes";
import {ChildUser, ParentUser} from "./types/auth";

export default function App() {
  const [user, setUser] = useState(null);

  const changeUser = useCallback((user) => {
    if (user && user.roles && user.roles.length !== 0) {
      if (user.roles.includes('parent')) {
        setUser(new ParentUser(user));
      } else {
        setUser(new ChildUser(user))
      }
    } else {
      setUser(user);
    }
  }, []);

  const theme = useMemo(() => createTheme(), []);
  const sounds = useMemo(() => createSounds({
    shared: { volume: 1 },
    players: {
      click: {
        sound: { src: ['/sounds/click.mp3'] },
        settings: { oneAtATime: true }
      },
      typing: {
        sound: { src: ['/sounds/typing.mp3'] },
        settings: { oneAtATime: true }
      },
      deploy: {
        sound: { src: ['/sounds/deploy.mp3'] },
        settings: { oneAtATime: true }
      }
    }
  }), []);

  useEffect(() => {
    const unsubscribe = firebaseAuth.onAuthStateChanged(usr => {
      // If the user logged in and isn't in state
      if (usr && !user) {
        getUsrData(usr)
          .then(usrData => changeUser(usrData))
          .catch(err => console.error(err));
        // User explicitly logged out
      } else if (!usr) {
        changeUser(null);
      }
    });
    return () => unsubscribe();
  }, [user]);

  return (
    <ThemeProvider theme={theme}>
      <SoundsProvider sounds={sounds}>
      <Arwes animate>
        <ErrorBoundary>
          <AuthContext.Provider value={{
            user,
            changeUser
          }}>
            <RxJSContext.Provider value={rxjsVal}>
              <Router>
                <Switch>
                  <Route path="/login" exact component={Login}/>
                  <PrivateRoute path="/" component={UserDash} fallbackComp={PublicView}/>
                </Switch>
              </Router>
            </RxJSContext.Provider>
          </AuthContext.Provider>
        </ErrorBoundary>
      </Arwes>
      </SoundsProvider>
    </ThemeProvider>
  );
}
