// @flow

export function getMost(dir: "NW" | "NE" | "SW" | "SE", latLngs: Array<{ lat: number | Function, lng: number | Function}>) {
  console.log("Got most");
  const getHighest = (highest: boolean, previous: number, newVal: number) => {
    if (previous > newVal) {
      return highest ? previous : newVal;
    } else {
      return highest ? newVal : previous;
    }
  };

  const getLatLngVal = (latHighest: boolean, lngHighest: boolean) => latLngs.reduce((prev, latLng) => {
    const currLat = typeof latLng.lat === 'function' ? latLng.lat() : latLng.lat;
    const currLng = typeof latLng.lng === 'function' ? latLng.lng() : latLng.lng;
    prev.lat = prev.lat !== null ? getHighest(latHighest, prev.lat, currLat) : currLat;
    prev.lng = prev.lng !== null ? getHighest(lngHighest, prev.lng, currLng) : currLng;
    return prev;
  }, {
    lat: null,
    lng: null
  });

  switch (dir) {
    case "NE":
      // get highest lat
      // get highest long
      return getLatLngVal(true, true);
    case "NW":
      // get highest lat
      // get lowest long
      return getLatLngVal(true, false);
    case "SE":
      // get lowest lat
      // get highest long
      return getLatLngVal(false, true);
    case "SW":
      // get lowest lat
      // get lowest long
      return getLatLngVal(false, false);
    default:
      return;
  }
}
