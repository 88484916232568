import React, {useState} from "react";
import {firebaseFunc} from "../../core/firebase";

export function CallFunction() {
  const [funcName, setFuncName] = useState("");
  const [data, setData] = useState("");
  const [messages, setMessages] = useState([]);
  const [errors, setErrors] = useState([]);

  const runFirebaseFunc = async (funcNameStuff, dataStuff) => {
    try {
      const firebaseNamedFunc = firebaseFunc.httpsCallable(funcNameStuff);
      const dataLocal = await firebaseNamedFunc(JSON.parse(dataStuff));
      setMessages(messages.concat(JSON.stringify(dataLocal)));
    } catch (e) {
      console.error(e);
      setErrors(errors.concat(JSON.stringify(e)));
    }
  };

  return <>
    <input placeholder="Function name" onChange={({target: {value}}) => setFuncName(value)}/>
    <input placeholder="Function data (must be valid JSON)" onChange={({target: {value}}) => setData(value)}/>
    <button type="button" onClick={() => runFirebaseFunc(funcName, data)}>Run Function</button>
    <button type="button" onClick={() => {
      setErrors([]);
      setMessages([]);
    }}>Clear</button>
    <div>
      {
        errors.map((err, i) =>
          <code style={{whiteSpace: 'pre', display: 'block', color: 'red'}} key={i}> {err} </code>
        )
      }
      {
        messages.map((message, i) =>
          <code style={{whiteSpace: 'pre', display: 'block'}} key={i}> {message} </code>
        )
      }
    </div>
  </>;
}
