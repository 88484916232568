// @flow

import React, {useState, useEffect} from "react";
import GoogleMap from "./google-map";

export default function SeeLocation(location: {lat: number, lng: number}) {
  const google = window.google;
  const [localMap, setlocalMap] = useState(false);

  const onMapEnable = (mapObj) => {
    if (mapObj) {
      setlocalMap(mapObj)
    }
  };

  useEffect(() => {
    if (localMap && location.lat && location.lng) {
      localMap.setCenter(location);
      const marker = new google.maps.Marker({
        position: location,
        map: localMap
      });
      return () => void marker.setMap(null);
    }
  }, [localMap, location]);

  return <GoogleMap onMapEnable={onMapEnable}/>
}
