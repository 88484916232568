// @flow

import type {ComponentType} from "react";
import React, {useContext, useMemo} from "react";
import {Redirect, Route} from "react-router-dom";
import {AuthContext} from "../core/auth";

export default function PrivateRoute({component: Component, fallbackComp: FallbackComp = null, roles, ...rest}: { component: ComponentType, roles?: string[], fallbackComp?: ComponentType, [key: string]: any }) {
  const {user} = useContext(AuthContext);
  const isAllowed = useMemo(() => {
    if (!user) {
      return false;
    } else if (roles) {
      const isAllow = user.roles.some(usrRole => roles.some(role => usrRole === role));
      return isAllow;
    } else {
      return true;
    }
  }, [roles, user]);

  return <Route {...rest} render={
    (props) => isAllowed ? <Component {...props}/> : (FallbackComp ? <FallbackComp/> : <Redirect to='/login'/>)
  }/>
}
