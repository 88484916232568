import React from "react";
import {Image, Link} from "arwes";
import {ContactUsForm} from "../../components/contact/contact-form";

export default function Home() {
  return <div style={{
    margin: "0 auto",
    padding: 20,
    maxWidth: 600
  }}>
    <Image animate resources='/logo.png'>
      JeTerra - Explore new worlds, while exploring your neighborhood.
    </Image>
    <p>
      Explore a new galaxy as a member of the JeTerra intergalactic colony. Earn rewards and unlockable achievements just by being active and walking, biking, skateboarding, etc. to school. For kids, it’s a fun way to connect activity and games. It's that simple. Be active and get rewarded, upgrade your character and unlock new worlds and games to explore.

      For parents, you can set routes and help your students make their way to school. With additional safety features such as route progression views and timer settings (available via the online parent portal), JeTerra is fun for the whole family.
    </p>
    <ContactUsForm/>
    <Link href='/jeterra_privacy_policy.pdf'>Privacy Policy</Link>
  </div>
}
