// @flow

/**
 * Map through all keys in an object and call a CB. Assign that CB's return value to the key in question
 * @param object - The object to make this call on. This would otherwise be bound to `Object.prototype` but I don't like breaking the web
 * @param {objectMap} cb - The callback in order to run the `Object.map` function
 * @returns - The object with it's keys altered by the cb param
 */
// TODO: Replace with $ObjMap
export function ObjectMap<T: object>(object: T, cb: (value: $Values<T>, key: $Keys<T>, object?: T) => any): { [$Keys<typeof T>]: any } {
  return Object.keys(object).reduce((prev, key) => ({
    ...prev,
    [key]: cb(object[key], key, object)
  }), {});
}

export function GetMatchingKeys<T: object, TT: object>(obj1: T, obj2: TT) {
  const obj2Keys = Object.keys(obj2);
  return Object.keys(obj1).filter(key => obj2Keys.includes(key));
}

export function mapConstructor(props?: Object) {
  if (props) {
    GetMatchingKeys(this, props).forEach(key => {
      this[key] = props[key];
    });
  }
}
